<template>

  <div class="library__view">
    <h1 class="library__title">Typography</h1>

    <section class="library__section">
      <p class="library__section-title">Paragraph style</p>
      <p class="text text--lg">Large Body<br>
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam,  quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
      </p>
      <hr class="mt-3">
      <p>Default Body<br>
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam,  quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
      </p>
      <hr class="mt-3">
      <p class="text text--sm">Small Body<br>
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam,  quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
      </p>
    </section>

    <section class="library__section">
      <p class="library__section-title">Heading style</p>
      <h1 class="vb-heading vb-heading--h1">L'heading ha una grandezza di H1</h1>
      <hr class="mt-3">
      <h2 class="vb-heading vb-heading--h2">L'heading ha una grandezza di H2</h2>
      <hr class="mt-3">
      <h3 class="vb-heading vb-heading--h3">L'heading ha una grandezza di H3</h3>
    </section>

  </div>

</template>

<script>

export default {
  name: 'typography',

  components: {
  },

  props: {},

  data() {
    return {
    }
  },

  created() {
  },

  computed: {
  },

  mounted() {
  }
}
</script>

<style lang="scss">

</style>